<template>
  <div>
    <br />
    <div class="col-sm-12 text-right">
      <h1>Cobranzas</h1>
    </div>
    <br />
    <DataGridV2 :config="dataGridConfig" :data="dataSourceCobranzas.data" :select="seleccionar" @actions="dataGridActions" ref="dataTable">
      <div class="row">
        <div class="col-sm-2">
          <button class="btn warning-btn" @click="loadCobranzas()">Recargar</button>
        </div>
        <!--
        <div class="col-sm-2">
          <button  class="btn principal-btn" @click="crear">Nuevo</button>
        </div>
        -->
        <div class="col-sm-2">
          <button  class="btn btn-detalle btn-secondary w-100 rounded-0" @click="detalle">Detalle</button>
        </div>
        <!--
        <div class="col-sm-2">
          <button  class="btn secondary-btn" @click="editar">Editar</button>
        </div>
        <div class="col-sm-2">
          <button  class="btn danger-btn" @click="pre_eliminar">Eliminar</button>
        </div>
        -->
      </div>

    </DataGridV2>

    <Modal v-if="openModalForm" @close="openModalForm = false" class="bpb-modal">
      <form>
        <h2 class="title">Cobranza</h2>
        <div class="body">
          <CobranzaForm  ref="cobranzaForm" :accion="accionModal" :cobranza-data="cobranzaSelect" @close="openModalForm = false"></CobranzaForm>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 d-flex justify-content-end">
              <div class="col-sm-3">
                <input class="btn principal-btn"  value="Guardar" @click="$refs.cobranzaForm.submit()" />
              </div>
              <div class="col-sm-3">
                <button class="btn complementary-btn" @click="openModalForm = false">Cancelar</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>

    <Modal v-if="openModalEliminar" :options="{width: '30vw',type:'error'}" @close="openModalEliminar = false">
      <div class="title">Eliminarás {{ seleccion.length }} cobranzas seleccionadas</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Eliminarás todas las cobranzas que tengas seleccionadas <br/>¿Deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-4 offset-sm-4"><button class="btn secondary-btn" type="button" @click="eliminarCobranzas">Continuar</button></div>
          <div class="col-sm-4"><button class="btn danger-btn" type="button" @click="openModalEliminar = false">Cancelar</button></div>
        </div>
      </div>
    </Modal>

    <Cobranza @close="openModalDetalle = false" v-if="openModalDetalle" :cobranza_id="cobranzaSelect" :cobranza="cobranza"></Cobranza>
  </div>
</template>

<script>
import DataGridV2 from '@/components/DataGridV2'
import Modal from '@/components/Modal'
import Cobranza from "./Cobranza";
import api from '@/apps/cobranza_admin/api/cobranzas.api'
import utils from '@/helpers/utilidades'

export default {
  name: "Cobranzas",
  data(){
    return {
      openModalForm: false,
      openModalEliminar: false,
      openModalDetalle: false,
      accionModal: 'crear',
      dataGridConfig: {
        name: 'cobranzas',
        cols: {
          cuenta: 'Folio',
          nombre: 'Cliente',
          producto: 'Producto',
          fondeador: 'Fondeador',
          adeudo: 'Saldo',
          pago_minimo: 'Pago Minimo',
          propietario: 'Propietario',
          estatus_credito_label: 'Estatus de crédito',
          estatus_cobranza_label: 'Estatus de cobranza'
        }
        ,filters: {
          cols: {
            id: 'ID',
            cuenta: 'Cuenta',
            nombre: 'Nombre',
            estatus_cobranza: {
              text: 'Estatus Cobranza',
              options: {}
            },
            estatus_credito: {
              text: 'Estatus Credito',
              options: {}
            }
            ,created_at: 'Fecha de creación'
          }
        }
        ,paginator: {
          pagina_actual: 1
          ,total_registros: 1
          ,registros_por_pagina: 20
        }
        ,mutators: {
          pago_minimo: function(val) {
            return '$' + utils.moneyFormat(val);
          },
          adeudo: function(val) {
            return '$' + utils.moneyFormat(val);
          },
        },
        search: true,
        select_items: true
      },
      options: {
        page: 1,
        order_col: 'id',
        order_dir: 'desc',
        limit: 20,
        filters: []
      },
      seleccionar: true,
      seleccion: [],
      estatus_credito_list: [],
      estatus_cobranza_list: [],
      eliminar: false,
      dataSourceCobranzas: [],
      cobranza: null,
    }
  },
  components: {
    Cobranza,
    DataGridV2,
    Modal
  },
  computed: {
     cobranzaSelect(){
       return this.seleccion.length > 0 ? this.seleccion[0].id : null;
     }
  },
  mounted() {

  },
  methods: {
    detalle(){
      this.getCobranza();
    },
    crear(){
      this.openModalForm = true;
      this.accionModal = 'crear';
    },
    editar(){
      if(this.seleccion.length > 0){
        this.accionModal = 'editar';
        this.openModalForm = true;
      }else
        this.$helper.showMessage('Error','Tienes que seleccionar la cobranza a editar', 'error', 'alert');
    },
    pre_eliminar(){
      if(this.seleccion.length > 0){
        this.openModalEliminar = true;
      }else
        this.$helper.showMessage('Error','Tienes que seleccionar al menos una cobranza para eliminar', 'error', 'alert');
    },

    dataGridActions: function(tipo, valor) {
      if (tipo == 'options') {
        this.options = valor;
        this.loadCobranzas();
      }else {
        this.seleccion = valor;
        this.seleccionar = true;
      }
    },
    eliminarCobranzas(){
      this.openModalEliminar = false;
      this.$helper.showMessage('Eliminar cobranzas','Cobranzas eliminadas correctamente', 'info', 'alert');
    },

    async loadCobranzas() {
        let query = this.options;
        let dataSource = await api.all(query)
        let filters = dataSource.data.filters;
        this.dataSourceCobranzas = dataSource.data;
        this.dataGridConfig.paginator.pagina_actual = this.dataSourceCobranzas.current_page;
        this.dataGridConfig.paginator.total_registros = this.dataSourceCobranzas.total;
        this.dataGridConfig.paginator.registros_por_pagina = parseInt(this.dataSourceCobranzas.per_page);

        this.estatus_credito_list = this.estatus_filtrado(filters.status_credito, 'code', 'label');
        this.estatus_cobranza_list = this.estatus_filtrado(filters.status_cobranza, 'id', 'nombre');

        this.dataGridConfig.filters.cols.estatus_cobranza = this.estatus_cobranza_list;
        this.dataGridConfig.filters.cols.estatus_credito = this.estatus_credito_list;

        this.dataSourceCobranzas.data = this.dataSourceCobranzas.data.map(item => this.gridRowFormat(item));

    },

    gridRowFormat(cobranza_data){
      let info_credito = cobranza_data.informacion_credito;
      let estatus_credito = this.estatus_credito_list[info_credito.bank_statement.details.contractStatus]
      let estatus_cobranza = !cobranza_data.status_cobranza_id ? '' : this.estatus_cobranza_list[cobranza_data.status_cobranza_id]
      return {
        id: cobranza_data.id,
        cuenta: cobranza_data.cuenta,
        nombre: cobranza_data.nombre,
        producto: info_credito.bank_statement.details.contractProduct,
        fondeador: info_credito.bank_statement.details.contractFunder,
        adeudo: info_credito.amortization_detail.idealPayment,
        pago_minimo: info_credito.bank_statement.details.creditIdealPayment,
        propietario: !(cobranza_data.usuario) ? '' : cobranza_data.usuario.nombre_completo ,
        estatus_credito_label: estatus_credito ,
        estatus_cobranza_label: estatus_cobranza,
        direccion: cobranza_data.direccion,
        latitud: cobranza_data.latitud,
        longitud: cobranza_data.longitud,
        cartera: cobranza_data.cartera.nombre
      }
    },

    estatus_filtrado(array, key, value){
      return (!array)? {} : array.reduce((item, val) => {
        item[ val[key] ] = val[value];
        return item;
      }, {});
    },

    async getCobranza() {
      try {
        let dataSource = await api.find(this.cobranzaSelect);
        this.cobranza = dataSource.data;
        let totales = {visitas_presenciales: 0, visitas_virtuales: { total: 0, pendientes: 0, pendientes_resumen: 0 }}
        const moment = this.$moment;
        let visitas = [
                ...this.cobranza.visitas.map(
                    function (item_visita) {
                      if (!item_visita.reunion_virtual) {
                        totales.visitas_presenciales++;
                      } else {
                        totales.visitas_virtuales.total++;
                      }
                      return {
                        fecha: item_visita.created_at,
                        tipo: 'visita',
                        data: item_visita
                      };
                    }),
                ...this.cobranza.reuniones_virtuales_pendientes.map(function (item_evento) {

                  let estatus_pendiente = moment(item_evento.inicio).isAfter(moment());

                  totales.visitas_virtuales.total++;
                  if (estatus_pendiente) {
                    totales.visitas_virtuales.pendientes++;
                  } else {
                    totales.visitas_virtuales.pendientes_resumen++;
                  }
                  return {
                    fecha: item_evento.inicio,
                    tipo: 'evento',
                    data: {
                      reunion_virtual: item_evento,
                      pendiente: estatus_pendiente
                    }
                  }
                })
              ]
            .sort((itemA, itemB) => itemA.fecha - itemB.fecha)

        this.cobranza.visitas = {...totales, data: visitas }

      }finally {
        if(this.cobranza.id !== null) {
          this.openModalDetalle = true;
          this.accionModal = 'detalle';
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
.btn-detalle{
  font-family: "NunitoRegular";
  padding: 0.5rem !important;
}
.btn-detalle:hover{
  background: transparent;
  color: #545b62;
}
</style>
